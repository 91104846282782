import React, { useRef, useState } from 'react';
import {
  CompraB2BIcon,
  MiLineaCreditoIcon,
  ComunicadosIcon,
  ConfiguracionIcon,
  NotificationIcon,
  LogoutIcon,
  IncidenciaIcon,
  ArrowSideBarWhite,
} from '@/shared/icons';
import Link from 'next/link';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';
import styles from './CustomSidebarCollapsed.module.scss';
import CustomOverlay from '../customOverlay/CustomOverlay';
import { ClickAwayListener } from '@mui/material';
import { logout } from '@/shared/functions/logout';
import { useRouter } from 'next/router';
import { Customer } from '@commercetools/platform-sdk';
import { rol } from '@/shared/roles/featureFlags';

const CustomSidebarCollapsed: React.FC<{ user: Customer | null}> = ({ user }) => {
  const router = useRouter();
  const targetCompraB2B = useRef(null);
  const targetMisVentasECommerce = useRef(null);
  const targetMovimientos = useRef(null);
  const targetIncidencias = useRef(null);
  const targetConfiguracion = useRef(null);

  const enum CONST_ICONS {
    COMPRA_B2B = 'COMPRA_B2B',
    MIS_VENTAS = 'MIS_VENTAS',
    MOVIMIENTOS = 'MOVIMIENTOS',
    INCIDENCIAS = 'INCIDENCIAS',
    COMUNICADOS = 'COMUNICADOS',
    CONFIGURACION = 'CONFIGURACION',
    LOG_OUT = 'LOG_OUT',
  }

  const [showTooltip, setShowTooltip] = useState('');

  const handleClick = (clickedIcon: string) => {
    //toggle
    if (clickedIcon === showTooltip) {
      setShowTooltip('');
      return;
    }

    setShowTooltip(clickedIcon);
  };

  const isIconSelected = (iconSelected: string) => showTooltip === iconSelected;
  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowTooltip('');
      }}
    >
      {/* role presentation for accesibility of screen readers*/}
      <div className={styles['container']} role="presentation">
        <div className={styles['notification-container']}>
          <div>
            <NotificationIcon />
          </div>
        </div>
        <hr />
        <div
          className={isIconSelected(CONST_ICONS.COMPRA_B2B) ? styles['seleccionado'] : ''}
          ref={targetCompraB2B}
          onClick={() => {
            handleClick(CONST_ICONS.COMPRA_B2B);
          }}
        >
          <CompraB2BIcon color={isIconSelected(CONST_ICONS.COMPRA_B2B) ? '#FFF' : '#165EEB'} />
          <CustomOverlay targetRef={targetCompraB2B} show={showTooltip === CONST_ICONS.COMPRA_B2B}>
            <ul className={styles['list']}>
              <Link href={CONST_REDIRECTS.ORDERS}>
                <li>Órdenes</li>
              </Link>
              <Link href={CONST_REDIRECTS.PLP}>
                <li>Catálogo</li>
              </Link>
              <Link href={CONST_REDIRECTS.SHOPPING_LIST}>
                <li>Lista de compras</li>
              </Link>
            </ul>
          </CustomOverlay>
        </div>
        {rol[user?.custom?.fields.roles]?.['seeMovementsTab'] && (
          <div
            onClick={() => {
              handleClick(CONST_ICONS.MOVIMIENTOS);
            }}
            ref={targetMovimientos}
            className={isIconSelected(CONST_ICONS.MOVIMIENTOS) ? styles['seleccionado'] : ''}
          >
            <MiLineaCreditoIcon color={isIconSelected(CONST_ICONS.MOVIMIENTOS) ? '#FFF' : '#165EEB'} />
            <CustomOverlay targetRef={targetMovimientos} show={showTooltip === CONST_ICONS.MOVIMIENTOS}>
              <ul className={styles['list']}>
                {rol[user?.custom?.fields.roles]?.['seeRotoplasCredit'] && (
                  <Link href={CONST_REDIRECTS.ROTOPLAS_CREDIT}>
                    <li>Facilidades Rotoplas</li>
                  </Link>
                )}
                <Link href={CONST_REDIRECTS.TRANSACTION_HISTORY}>
                  <li>Descarga tus facturas</li>
                </Link>
              </ul>
            </CustomOverlay>
          </div>
        )}
        <div
          onClick={() => {
            handleClick(CONST_ICONS.INCIDENCIAS);
          }}
          ref={targetIncidencias}
          className={isIconSelected(CONST_ICONS.INCIDENCIAS) ? styles['seleccionado'] : ''}
        >
          <IncidenciaIcon color={isIconSelected(CONST_ICONS.INCIDENCIAS) ? '#FFF' : '#165EEB'} />
          <CustomOverlay targetRef={targetIncidencias} show={showTooltip === CONST_ICONS.INCIDENCIAS}>
            <ul className={styles['list']}>
              <Link href={CONST_REDIRECTS.CANCELATIONS}>
                <li>Cancelaciones</li>
              </Link>
              <Link href={CONST_REDIRECTS.DEVOLUTIONS}>
                <li>Devoluciones</li>
              </Link>
            </ul>
          </CustomOverlay>
        </div>
        <div
          onClick={() => {
            handleClick(CONST_ICONS.COMUNICADOS);
            router.push(CONST_REDIRECTS.ANNOUNCEMENTS);
          }}
          className={isIconSelected(CONST_ICONS.COMUNICADOS) ? styles['seleccionado'] : ''}
        >
          <Link href={CONST_REDIRECTS.ANNOUNCEMENTS}>
            <ComunicadosIcon color={isIconSelected(CONST_ICONS.COMUNICADOS) ? '#FFF' : '#165EEB'} />
          </Link>
        </div>
        <div
          onClick={() => {
            handleClick(CONST_ICONS.CONFIGURACION);
          }}
          ref={targetConfiguracion}
          className={isIconSelected(CONST_ICONS.CONFIGURACION) ? styles['seleccionado'] : ''}
        >
          {' '}
          <ConfiguracionIcon color={isIconSelected(CONST_ICONS.CONFIGURACION) ? '#FFF' : '#165EEB'} />
          <CustomOverlay targetRef={targetConfiguracion} show={showTooltip === CONST_ICONS.CONFIGURACION}>
            <ul className={styles['list']}>
              <Link href={CONST_REDIRECTS.ACCOUNT_CONFIGURATION}>
                <li>Mi cuenta</li>
              </Link>
              {rol[user?.custom?.fields.roles]?.['seeUserManagement'] && (
                <Link href={CONST_REDIRECTS.USERS_MANAGEMENT}>
                  <li>Administración de usuarios</li>
                </Link>
              )}
            </ul>
          </CustomOverlay>
        </div>

        <div
          onClick={() => {
            logout();
          }}
          className={` ${styles['log-out-container']} ${
            isIconSelected(CONST_ICONS.LOG_OUT) ? styles['seleccionado'] : ''
          }`}
        >
          {' '}
          <LogoutIcon color={isIconSelected(CONST_ICONS.LOG_OUT) ? '#FFF' : '#165EEB'} />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CustomSidebarCollapsed;

import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import Pagination from '@mui/material/Pagination';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import DeleteIcon from '@/shared/icons/DeleteIcon';
import CustomDropdown from '@/shared/components/customDropdown/CustomDropdown';
import ButtonAdd from '@/shared/components/buttonAdd/ButtonAdd';
import Button from '@/shared/components/button/Button';
import CustomGrid from '@/shared/components/grid/CustomGrid';
import {
    addProductToCartWithShippingAddress,
    removeProductToCartWithShippingAddress,
} from '@/shared/functions/commerceTools';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // optional theme
import 'ag-grid-community/styles/ag-theme-alpine.css'; // optional theme
import { Toast, notifyError, notifySuccess } from '@/shared/components/toast/Toast';
import { formatCurrency } from '@/shared/functions/currencies';
import CustomModal from '@/shared/components/modal/Modal';
import { ShoppingCartEmptyIcon, PlusIcon } from '@/shared/icons';
import { EmptyState } from '@/shared/components/grid/EmptyState';
import { rol } from '@/shared/roles/featureFlags';
import { Attribute, Customer } from '@commercetools/platform-sdk';
import { AppContext } from 'context/appContext/AppContextProvider';
import type {IContext} from "context/appContext/AppContextProvider"

type ItemsProduct = {
    sucursal: string;
    Cantidad: number;
    Descuento: string;
    Subtotal: string;
    Total: string;
    isDelete: string;
    key: string;
    id: string;
    idLineItems: string;
};

const renderProduct = (params: any) => {
    return (
        <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', height: '100%', gap: '30px' }}>
            <img style={{ width: '45px', height: '45px' }} alt="producto" src={params.value.image} />
            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                <span style={{ height: '31px', fontSize: '0.9rem' }}>{params.value.type}</span>
                <span style={{ height: '31px', fontSize: '0.9rem' }}>{params.value.key}</span>
                <span style={{ height: '31px', fontSize: '0.9rem' }}>SKU: {params.value.sku}</span>
            </div>
        </div>
    );
};

const renderSucursal = (params: any) => {
    const router = useRouter();
    const customer = params.customer;
    return (
        <div>
            {params?.value?.map((item: ItemsProduct) => {
                return <div className={styles['cell']}>{item?.sucursal ?? <></>}</div>;
            })}
            {rol[customer?.custom?.fields.roles]?.['haveBranches'] && (
                <div className={styles['cell']}>
                    <div style={{ height: '30px' }}>
                        <CustomDropdown
                            className={styles['add-sucursal']}
                            onChange={async (e: any) => {
                                const res = await addProductToCartWithShippingAddress({
                                    addressKey: e.target.value,
                                    sku: params.data.sku,
                                    quantity: 1,
                                    product: params.data,
                                });

                                if (!res.wentOk) {
                                    alert('no se pudo agregar');
                                    return;
                                }
                                router.reload();
                            }}
                            items={params?.data?.listBusiness}
                            name={'addSucursal-' + params.rowIndex}
                            label="Añadir sucursal"
                            icon={<PlusIcon />}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const renderCount = (params: any) => {
 const context:IContext=params.context
 const quantityFromCT = params.data.quantity;
 const [quant, setQuant] = useState(quantityFromCT);
 const sku = params.data.Producto.sku;
 const lineItemId = params.data.Producto.lineItemId;

 const minimalquantity=params.data.Producto?.attributes?.find((attr:Attribute)=>attr.name==="minimalquantity")?.value ||1
 const incremental=params.data.Producto?.attributes?.find((attr:Attribute)=>attr.name==="incremental")?.value ||1


 const addressKey = params.data.branch.bussinessUnit.key;
    return (
        <>
            <div className={styles['cell']}>
                <div style={{ height: '30px', width: '100%' }}>
                    <ButtonAdd
                        quantity={quant}
                        onAdd={async () => {

                            setQuant(quant+incremental)
                            params.callback_updateProductsOnCart({
                                quantityFromCT,
                                quantity: quant + incremental,
                                sku,
                                addressKey,
                                lineItemId,
                              });

                        }}
                        onRemove={async () => {
                            let newQuantity=quant-incremental
                            if(newQuantity< minimalquantity) newQuantity=minimalquantity

                            setQuant(Math.max(minimalquantity,newQuantity));

                            params.callback_updateProductsOnCart({
                                quantityFromCT,
                                quantity: Math.max(minimalquantity,newQuantity),
                                sku,
                                addressKey,
                                lineItemId,
                              });

                        }}
                    />
                </div>
            </div>
        </>
    );
};

const renderDiscount = (params: any) => {
    return (
        <div>
            {params?.data?.Sucursal?.map((item: ItemsProduct) => {
                return <div className={styles['cell']}>{item?.Descuento ?? <></>}</div>;
            })}
        </div>
    );
};

const renderSubtotal = (params: any) => {
    return (
        <div>
            {params?.data?.Sucursal?.map((item: ItemsProduct) => {
                return <div className={styles['cell']}>{item?.Subtotal ?? <></>}</div>;
            })}
        </div>
    );
};

const renderIVA = (params: any) => {
    return (
        <>
            {params?.data?.IVAs?.map((ivaAmount: number) => {
                return <div className={styles['cell']}>{formatCurrency(ivaAmount)}</div>;
            })}
        </>
    );
};

const renderTotal = (params: any) => {
    return (
        <div>
            {params?.data?.Sucursal?.map((item: ItemsProduct) => {
                return <div className={styles['total']}>{item?.Total ?? <></>}</div>;
            })}
        </div>
    );
};

const CompraRapidaGrid = ({ rows, customer }: { rows: any[]; customer: Customer | null}) => {
    const router = useRouter();
    const context= useContext(AppContext)

    const [showModal, setShowModal] = useState<boolean>(false);
    const [item, setItem] = useState<any>({});
    // const [params, setParams] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let idTimeout: any;

    const callback_updateProductsOnCart = ({
        quantityFromCT,
        quantity,
        sku,
        addressKey,
        lineItemId,
      }: {
        quantityFromCT: number;
        quantity: number;
        sku: string;
        addressKey: string;
        lineItemId: string;
      }) => {
        clearTimeout(idTimeout);
        idTimeout = setTimeout(async () => {
          if (quantity > quantityFromCT) {
            const quantityToUpdate = quantity - quantityFromCT;
            const res = await addProductToCartWithShippingAddress({ quantity: quantityToUpdate, sku, addressKey });
            if (!res.cart) {
              notifyError('error al añadir producto al carrito');
              return;
            }
            context.setCart(res.cart);
          }
          if (quantity < quantityFromCT) {
            const quantityToUpdate = quantityFromCT - quantity;
            const res = await removeProductToCartWithShippingAddress({
              quantity: quantityToUpdate,
              lineItemId,
              addressKey,
            });
            if (!res.cart) {
              notifyError('error al remover producto del carrito');
              return;
            }
            context.setCart(res.cart);
          }
        }, 2000);
      };
    

    const renderDelete = (params: any) => {
        return (
            <div style={{ position: 'relative', left: '-11px' }}>
                <div className={styles['cell']}>
                    <div>
                        <Button
                            variant="icon"
                            onClick={() => {
                                setShowModal(true);
                                // setParams(params);
                                setItem(params.data);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const [columnDefs] = useState([
        { field: 'Producto', cellRenderer: renderProduct, lockPosition: 'left', unSortIcon: true },
        {
            field: 'Costo unitario',
            headerName: 'Precio unitario*',
            width: 97,
            type: 'rightAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
        {
            field: 'Sucursal',
            headerName: "Sucursal",
            // cellRenderer: renderSucursal,
            cellRendererParams: {
                customer,
            },
            autoHeight: true,
            minWidth: 155,
            type: 'leftAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
        {
            field: 'quantity',
            headerName: 'Cantidad',
            cellRenderer: renderCount,
            cellRendererParams:{
                context,
                callback_updateProductsOnCart
            },
            minWidth: 150,
            type: 'rightAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
        // { field: 'Descuento', cellRenderer: renderDiscount, flex: 1, type: 'rightAligned', lockPosition: 'left' },
        // {
        //     field: 'subtotal',
        //     headerName: 'Subtotal',
        //     // cellRenderer: renderSubtotal,
        //     flex: 1,
        //     type: 'rightAligned',
        //     lockPosition: 'left',
        //     unSortIcon: true,
        //     minWidth: 155,
        // },
        // {
        //     field: 'iva',
        //     // cellRenderer: renderIVA,
        //     flex: 1,
        //     headerName: 'IVA',
        //     type: 'rightAligned',
        //     lockPosition: 'left',
        //     unSortIcon: true,
        //     minWidth: 155,
        // },
        {
            field: 'total',
            headerName: 'Total',
            // cellRenderer: renderTotal,
            flex: 1,
            type: 'rightAligned',
            lockPosition: 'left',
            unSortIcon: true,
            minWidth: 155,
        },
        {
            field: 'Delete',
            cellRenderer: renderDelete,
            headerName: '',
            width: 10,
            type: 'rightAligned',
            lockPosition: 'left',
            sortable: false,
        },
    ]);

    const autoSizeStrategy = {
        type: 'fitCellContents',
    };

    const handleDeleteProductFromBranch = async () => {
        setIsLoading(true);
        const resRemoveProduct = await removeProductToCartWithShippingAddress({
            quantity: item?.quantity,
            addressKey: item?.branch.bussinessUnit.key,
            lineItemId: item?.idLineItems,
        });

        if (!resRemoveProduct.cart) {
            notifyError('No se eliminó correctamente el producto');
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        notifySuccess("producto eliminado del carrito")
        context.setCart(resRemoveProduct.cart)
        setShowModal(false);
        // router.reload();
    };


    return (
        <>
            <CustomModal
                label1="¿Estás seguro de querer eliminar el producto?"
                labelButton1="No eliminar producto"
                labelButton2="Sí, eliminar producto"
                variant={isLoading? "disabled":"secondary"}
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                onCancel={() => {
                    handleDeleteProductFromBranch();
                }}
                onClick={() => {
                    setShowModal(false);
                }}
            >
                <hr />
                <div style={{ textAlign: 'center' }}>El producto se eliminará de tu carrito</div>
            </CustomModal>
            <CustomGrid
                pagination={true}
                autoSizeStrategy={autoSizeStrategy}
                rowHeight={93}
                height={500}
                rowData={rows}

                // @ts-ignore
                columnDefs={columnDefs}
                // @ts-ignore
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{
                    icon: <ShoppingCartEmptyIcon />,
                    text: 'Aún no tienes productos en tu carrito, puedes agregar productos por SKU, revisando nuestro catálogo o subiendo un archivo CSV.',
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', height: '50px', alignItems: 'center' }}>
                {/* <Pagination
          page={pagination.activePage}
          count={Math.ceil(rows.length / (gridApi?.paginationGetPageSize() || 1))}
          color="primary"
          onChange={handlePagination}
        /> */}
            </div>
        </>
    );
};

export default CompraRapidaGrid;

import React, { useEffect, useState,useContext } from 'react';
import Link from 'next/link';
import styles from './CompraRapida.module.scss';
import { CompraRapida, CloseIcon, ArrowUp, ArrowDown, CloudUploadIcon } from '@/shared/icons';
import Options from '../comprarapida/Options';
import CompraBySku from '../comprarapida/compra-by-sku/CompraSku';
import CompraByCsv from '../comprarapida/compra-by-csv/CompraCsv';
// Material UI Drawer
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@/shared/components/button/Button';
import { formatCurrency } from '@/shared/functions/currencies';
import CompraByList from '../comprarapida/compra-by-lista/CompraList';
import ResumenBuy from '../comprarapida/resumen-buy/ResumenBuy';
import { loadBusinessUnits, sdk_loadCart } from '@/shared/functions/commerceTools';
import CompraRapidaGrid from './CompraRapidaGrid';
import  CONST_REDIRECTS  from '@/shared/consts/CONST_REDIRECTS.json';
import Loader from '@/shared/components/loader/Loader';
import { useRouter } from 'next/router';
import { IVA } from '@/shared/consts/CONST_IVA.json';
import { AppContext } from 'context/appContext/AppContextProvider';
import { getBusinessToDropdown } from 'context/appContext/initialLoading';

type Anchor = 'right';

const CompraRapidaDrawer = () => {
  const context=useContext(AppContext)
  const [state, setState] = React.useState({ right: false });
  const [showLoader, setShowLoader] = useState(false);
  const [component, setComponent] = useState('select')
  const [products, setProducts] = useState<any>([]);
  const [lastComponent, setLastComponent] = useState(component)
  const router = useRouter();

  const mapProductsFromCartContext = async () => {

    
    const products =
      context.appContext.cart?.lineItems?.map((item) => {
        // const costoUnitarioNet = (item?.taxedPrice?.totalNet?.centAmount || 0) / 100 / item.quantity;
        const costoUnitarioNet = item.totalPrice.centAmount / item.quantity;
        // const costoUnitarioGross = (item?.taxedPrice?.totalGross?.centAmount || 0) / 100 / item.quantity;
        const costoUnitarioGross = item.totalPrice.centAmount / item.quantity;

        const variantId = item?.variant?.id;
        const Producto = {
          image: item?.variant?.images?.[0]?.url,
          type: item?.name["es-MX"],
          sku: item?.variant?.sku,
          lineItemId: item.id,
          attributes: item.variant.attributes
        };

        const IVAPerUnitCents = costoUnitarioGross - costoUnitarioNet;


        const branch = context.appContext.bussinessUnits.find((bu) => {
          return item.shippingDetails?.targets.find((sd) => bu.bussinessUnit.key === sd.addressKey);
        })
        const idLineItems = item?.id;
        const id = item?.productId;
        const sku = item?.variant?.sku;
        const listBusiness = getBusinessToDropdown(context.appContext.bussinessUnits);
        return {
          idLineItems,
          'Costo unitario': formatCurrency(costoUnitarioNet / 100),
          quantity:item.quantity,
          subtotal:formatCurrency((costoUnitarioNet * item.quantity) / 100),
          total:formatCurrency((costoUnitarioGross * item.quantity) / 100),
          Producto,
          Sucursal:branch?.state || "state",
          branch,
          id,
          variantId,
          iva: formatCurrency((IVAPerUnitCents * item.quantity) / 100),
          sku,
          listBusiness,
        };
      }) || [];

    setProducts([...products]);
  };



  useEffect(() => {
    if(context.appContext.bussinessUnits.length===0 || !context.appContext.cart) return
    mapProductsFromCartContext()
  },[context.appContext.bussinessUnits,context.appContext.cart])

  useEffect(() => {
    if(component == 'by-resumen'){
      mapProductsFromCartContext()
    }
  }, [component,context.appContext.bussinessUnits,context.appContext.cart])

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleCloseDrawer = () => {
    setState({ ...state, right: false });
  };

  return (
    <>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <div className={styles['containter-button']}>
            <button className={styles['button-compras-rapidas']} onClick={toggleDrawer(anchor, true)}>
              <div className={styles['content-button']}>
                <div className={styles['content-desk']}>
                  <div className={styles['icon-button']}>
                    <CompraRapida/>
                  </div>
                  <div>Compra rápida</div>
                </div>
                <div className={styles['content-mob']}>
                  <CloudUploadIcon/>
                </div>

              </div>
            </button>
          </div>

          <div style={{ height: '100%', borderRadius: '10px 0 0 10px' }}>
            <SwipeableDrawer
              anchor={anchor}
              style={{ height: "100%", borderRadius: '10px 0 0 10px' }}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              transitionDuration={{enter: 500, exit: 800}}
            >
              <div className={styles['container-compra-rapida']}>
              { showLoader ? <Loader message="Espera un momento, estamos agregando tu(s) producto(s)" /> : <></>}
                {/* <div className={showLoader? styles['contain-loader'] : styles['hideLoader']}>
                  {/* <div className={showLoader? styles['loader'] : styles['hideLoader']}></div> 
                  
                </div> */}
                <div className={ component !== 'by-resumen' ? styles['container-draw'] : styles['container-resumen']}>
                  { component == 'select' && <Options setComponent={setComponent} closeDrawer={ handleCloseDrawer } /> }
                  { component == 'by-sku' && <CompraBySku setComponent={ setComponent } setShowLoader={ setShowLoader } /> }
                  { component == 'by-csv' && <CompraByCsv setComponent={ setComponent } setShowLoader={ setShowLoader } /> }
                  { component == 'by-list' && <CompraByList setComponent={ setComponent } setShowLoader={ setShowLoader } /> }
                  { component == 'by-resumen' && <ResumenBuy closeDrawer={ handleCloseDrawer } /> }
                  <div className={component == 'by-resumen' ? styles['container-footer-resumen'] : styles['container-footer']}>
                    <div className={styles['contain-flex']}
                    onClick={() => {
                      if(component === 'by-resumen') {
                        setComponent(lastComponent)
                        return
                      }
                      setLastComponent(component)
                      setComponent('by-resumen')
                    }}
                    >
                      <h3>Resumen de compra</h3>
                      <div className={styles['arrow']}>
                        {
                          component !== 'by-resumen'?
                            <ArrowUp />
                            :
                            <span className={styles['arrow-open']}>
                              <ArrowUp />
                            </span>
                            
                        }
                      </div>
                    </div>
                    {
                      component === 'by-resumen' && <div className={styles['shopping-cart-table']}>
                        <CompraRapidaGrid rows={products} customer={context.appContext.customer} />
                      </div>
                    }
                      <div className={styles['contain-flex']}
                      onClick={() => { setComponent('select') }}
                      >
                        <small className={styles['contain-flex-total']}> Total:</small>
                        <p style={{ fontSize: '1.3rem', color:'#002554', fontWeight: 'bold' }}>{ context.appContext.cart?.totalPrice?.centAmount? formatCurrency(context.appContext.cart.totalPrice.centAmount / 100) : '$0' }</p>
                      </div>

                    <div className={styles['contain-flex']}>
                      <div className={styles['contain-flex-button']}>
                        <Button onClick={() => router.asPath == CONST_REDIRECTS.CART ? handleCloseDrawer() : ''}>
                          <Link href="/cart">Ir a mi carrito</Link>
                        </Button>
                      </div>
                      <div className={styles['contain-flex-button2']}>
                        <Button variant='secondary' onClick={ () => router.push("/checkout") } disabled={ products.length > 0 ? false : true }>
                          <a> Iniciar compra</a>
                        </Button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
            </SwipeableDrawer>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default CompraRapidaDrawer;

import React, { CSSProperties, ReactElement } from 'react';

import styles from './styles.module.scss';

const ContenedorTitulo = (props: {
  titulo: string;
  icon?: React.ReactNode;
  children: React.FunctionComponent | ReactElement | string | any;
  removeBorder?:boolean;
  style?: CSSProperties;
  unsetHeight?:boolean;
}) => {
  return (
    <div className={styles['container']} style={props.style}>  
      <div className={styles['header']} style={{borderBottom:props.removeBorder?"0":"inherit"}}>
        <p className={styles['titulo']}>{props.titulo}</p>
        {props.icon && <div className={styles['icon']}>{props.icon}</div>}
      </div>
      <div className={`${styles['body']} ${styles[props.unsetHeight?"unset-height":""]}`}>{props.children}</div>
    </div>
  );
};

export default ContenedorTitulo;

import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '@/shared/components/mainLayout/MainLayout';
import ContenedorTitulo from '@/shared/components/contenedorTitulo/ContenedorTitulo';
import { BlindEyeIcon, UserIcon, LocationIcon, OpenEyeIcon } from '@/shared/icons';
import styles from './styles.module.scss';
import { apiRoot, ctCustomer } from '@/shared/client';
import { notifyError, notifySuccess, Toast } from '@/shared/components/toast/Toast';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import Modal from '@/shared/components/modal/Modal';
import { useForm } from 'react-hook-form';
import Button from '@/shared/components/button/Button';
import { api_login } from '@/shared/api_calls/token';
import Grid from './Grid';
import { AccountGridRowType } from './Grid';
import Skeleton from '@/shared/components/skeleton/Skeleton';
import { decryptWithAES, encryptWithAES } from '@/shared/functions/encrypt';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { AppContext } from 'context/appContext/AppContextProvider';

type FormValues = {
    currentPassword: string;
    newPassword: string;
};

type IRolMapper = Record<'superR'| 'Super Usuario asociado' | 'adminR' | 'PDVOperatorR' | 'PDVOperatorRA'  | 'customerR' | 'customerRA' | 'AliadoVABR' | any, string>;

export const rolMapper: IRolMapper = {
    superR: 'Super administrador',
    'Super Usuario asociado': 'Super administrador asociado',
    adminR: 'Administrador',
    PDVOperatorR: 'Operador',
    PDVOperatorRA: 'Operador asociado',
    customerR: 'Comprador',
    customerRA: 'Comprador asociado',
    AliadoVABR: 'Aliado VAB'
};

const AccountConfiguration = () => {
    const context = useContext(AppContext)
    const [showModal, setShowModal] = useState<boolean>(false);

    const [rows, setRows] = useState<AccountGridRowType[]>([]);

    const [togglePasswordView, setTogglePasswordView] = useState<boolean>(false);
    const [togglePasswordView2, setTogglePasswordView2] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        resetField,
        setError,
        clearErrors,
        setValue,
    } = useForm<FormValues>();

    const currentPasswordWatcher = watch('currentPassword');
    const newPasswordWatcher = watch('newPassword');


    const changePassword = async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
        if (!context.appContext.customer) return;
        const loginEncrypt = getCookieValue(findCookie('login'));
        const login = decryptWithAES(loginEncrypt)
        const { Email, Password, BussinesUnitParent } = ctCredentials(login);
        try {
            const me = await ctCustomer(Email, Password).me().get().execute()
            const resChangePassword = await apiRoot
                .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
                .customers()
                .password()
                .post({
                    body: {
                        id: context.appContext.customer.id,
                        version: me.body.version,
                        currentPassword: oldPassword,
                        newPassword: newPassword,
                    },
                })
                .execute();

            clearErrors('currentPassword');
            if (resChangePassword.statusCode !== 200) {
                notifyError('No se pudo actualizar su contraseña, intente nuevamente.');
                return;
            }
            notifySuccess('Su contraseña ha sido cambiada exitosamente');
            setShowModal(false);
            //delete old token cookie
            document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            //set cookie to know if its a change password action
            document.cookie = 'changedPassword=true; Path=/';

            //login
            const resLoginToken = await api_login(context.appContext.customer.email, newPassword);
            const resLogin = await apiRoot
                // .inStoreKeyWithStoreKeyValue({ storeKey: STORE_KEY })
                .login()
                .post({
                    body: {
                        email: context.appContext.customer.email,
                        password: newPassword,
                    },
                })
                .execute();
            if (!resLogin || resLogin.statusCode! >= 500) {
                console.error('error', resLogin);
                notifyError('No se pudo actualizar su contraseña, intente mas tarde.');
                window.location.href = '/login';
                return;
            }
            document.cookie = `token=${resLoginToken.data.access_token}; max-age=172800`;
            const bussinesbyIdCustomer = await apiRoot
                .asAssociate()
                .withAssociateIdValue({ associateId: resLogin.body.customer.id })
                .businessUnits()
                .get()
                .execute();
            if (!bussinesbyIdCustomer.statusCode || bussinesbyIdCustomer.statusCode >= 300) return;
            const encryptedLogin = encryptWithAES(
                `${resLogin.body.customer.email}, ${newPassword}, ${bussinesbyIdCustomer.body.results[0].key}, ${resLogin.body.customer.id}, ${resLogin.body.customer.externalId}`,
            );
            document.cookie = 'login=' + encryptedLogin+"; max-age=172800";
            resetField("newPassword")
            resetField("currentPassword")
        } catch (e: any) {
            if (e.status === 400) {
                setError('currentPassword', { type: 'invalidCurrentPassword' });
                notifyError('Contraseña actual incorrecta');
                return;
            }
            notifyError(e.message);
            return;
        }
    };

    const mapBussinessUnits = () => {
        const rows: AccountGridRowType[] = context.appContext.bussinessUnits.map((suc: any) => {
            const addressObj = suc.bussinessUnit?.addresses?.[0];
            const formattedAddress = `${addressObj?.streetName} ${addressObj?.streetNumber}, ${addressObj?.region}, ${addressObj?.postalCode} ${addressObj?.city}, ${addressObj?.state}`;
            return { branch: suc.state, address: formattedAddress };
        });
        setRows(rows);
    };

    useEffect(() => {
        if (context.appContext.bussinessUnits.length === 0) return
        mapBussinessUnits();
    }, [context.appContext.bussinessUnits]);

    const onSubmit = (formValues: FormValues) => {
        changePassword({ oldPassword: formValues.currentPassword, newPassword: formValues.newPassword.trim() });
    };

    const isLoaded = true;

    return (
        <>
            <Toast />
            <Modal
                label1="Cambiar contraseña"
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} className={styles['form-container']}>
                    <hr />
                    <div>
                        <label> Contraseña actual*</label>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <input
                                type={togglePasswordView ? 'text' : 'password'}
                                style={{ width: '100%' }}
                                placeholder="Escribe tu contraseña"
                                {...register('currentPassword', {
                                    required: true,
                                    onChange: (e) => {
                                        setValue('currentPassword', e.target.value.replaceAll(" ", ""));
                                    },
                                })}
                                className={errors.currentPassword ? styles['invalid'] : ''}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: '12px',
                                    top: '40%',
                                    cursor: 'pointer',
                                    width: '14px',
                                    height: '12px',
                                }}
                                onClick={() => {
                                    setTogglePasswordView(!togglePasswordView);
                                }}
                            >
                                {!togglePasswordView ? <BlindEyeIcon /> : <OpenEyeIcon />}
                            </div>
                        </div>
                    </div>
                    <div>
                        <label> Contraseña nueva*</label>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <input
                                type={togglePasswordView2 ? 'text' : 'password'}
                                style={{ width: '100%', marginBottom: '5px' }}
                                maxLength={20}
                                placeholder="Escribe una contraseña nueva"
                                {...register('newPassword', {
                                    required: true,
                                    minLength: 8,
                                    maxLength: 20,
                                    pattern: /^(?=.*[a-záéíóúüñ])(?=.*[A-ZÁÉÍÓÚÜÑ])(?=.*\d).{8,20}$/,
                                    onChange: (e) => {
                                        const newString = e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}=!]/g, '').trim()
                                        e.target.value = newString
                                        setValue('newPassword', newString);
                                    },
                                })}
                                className={errors.newPassword ? styles['invalid'] : ''}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: '12px',
                                    top: '40%',
                                    cursor: 'pointer',
                                    width: '14px',
                                    height: '12px',
                                }}
                                onClick={() => {
                                    setTogglePasswordView2(!togglePasswordView2);
                                }}
                            >
                                {!togglePasswordView2 ? <BlindEyeIcon /> : <OpenEyeIcon />}
                            </div>
                        </div>
                    </div>
                    <span style={{ color: errors.newPassword ? '#FF6060' : '#4F4F4F' }}>
                        Debe contener: 8 caracteres como mínimo y máximo 20 sin espacio, al menos una mayúscula y un número del 0 al
                        9.
                    </span>
                    <span style={{ color: errors.newPassword ? '#FF6060' : '#4F4F4F' }}>
                        No Debe contener: Caracteres especiales.
                    </span>
                    <div style={{ marginTop: '30px' }}>
                        <Button
                            variant={newPasswordWatcher && currentPasswordWatcher ? 'secondary' : 'disabled'}
                            style={{ height: '50px', width: '100%' }}
                            type="submit"
                        >
                            Cambiar contraseña
                        </Button>
                    </div>
                </form>
            </Modal>

            <MainLayout>
                <>
                    <Skeleton isLoaded={isLoaded}>
                        <ContenedorTitulo titulo="Mi cuenta" icon={<UserIcon color="#165EEB" />}>
                            <div className={styles['data-container']}>
                                <div className={styles['data-item']}>
                                    <label>Nombre y apellido</label>
                                    <label>{`${context.appContext.customer?.firstName} ${context.appContext.customer?.lastName}`}</label>
                                </div>
                                <div className={styles['data-item']}>
                                    <label>Correo</label>
                                    <label>{context.appContext.customer?.email}</label>
                                </div>
                                <div className={styles['data-item']}>
                                    <label>Tipo de usuario</label>
                                    <label>{rolMapper[context.appContext.customer?.custom?.fields?.roles] || ''}</label>
                                </div>
                                <div className={styles['data-item']}>
                                    <label>Contraseña</label>
                                    <label>************</label>
                                    <Button
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        Cambiar contraseña
                                    </Button>
                                </div>
                            </div>
                        </ContenedorTitulo>
                    </Skeleton>
                    <br />
                    <Skeleton isLoaded={isLoaded}>
                        <ContenedorTitulo titulo="Mis direcciones / unidades de negocio" icon={<LocationIcon color="#165EEB" />}>
                            <Grid rows={rows} />
                        </ContenedorTitulo>
                    </Skeleton>
                </>
            </MainLayout>
        </>
    );
};

export default AccountConfiguration;

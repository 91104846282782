import styles from './CustomBreadCrumb.module.scss';
import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';

const CustomBreadcrumb = () => {
  const router = useRouter();
  let getType: string | string[];

  const breadCrumbPaths: any = router.query.slug ? ['...', ...router.query.slug] : ['...'];
  if (router.query.productKey) {
    let index = router.query.productKey.indexOf(' ');

    getType =
      index !== -1
        ? (Array.isArray(router.query.productKey) ? router.query.productKey[0] : router.query.productKey)
            .substring(0, index)
            .charAt(0)
            .toUpperCase() +
          (Array.isArray(router.query.productKey) ? router.query.productKey[0] : router.query.productKey)
            .substring(0, index)
            .substring(1)
            .toLowerCase()
        : router.query.productKey;

    breadCrumbPaths[breadCrumbPaths.length] = router.query.productKey;
  }

  //creacion y cancelacion de orden
  if (router.query.orderNumber) {
    breadCrumbPaths[breadCrumbPaths.length] = `ID Orden ${router.query.orderNumber}`;
  }
  if (router.query.listId) {
    breadCrumbPaths[breadCrumbPaths.length] = router.query.listId;
  }

  // @ts-ignore
  const breadCrumbMappedNames: { name: string; redirectsTo: string }[] = breadCrumbPaths.map((path: string) => {
    switch (path) {
      case CONST_REDIRECTS.CART.substring(1): {
        return { name: 'Home / Mi carrito', redirectsTo: CONST_REDIRECTS.CART };
      }
      case '...': {
        return { name: '...', redirectsTo: CONST_REDIRECTS.HOME };
      }
      case CONST_REDIRECTS.PLP.substring(1): {
        return { name: 'Mis Compras / Catálogo', redirectsTo: CONST_REDIRECTS.PLP };
      }
      case 'pdp': {
        return { name: getType, redirectsTo: CONST_REDIRECTS.PLP };
      }
      case CONST_REDIRECTS.CHECKOUT.substring(1): {
        return { name: 'Checkout', redirectsTo: CONST_REDIRECTS.CHECKOUT };
      }
      case CONST_REDIRECTS.CHECKOUT_FINISHED.substring(1): {
        return { name: 'Checkout', redirectsTo: CONST_REDIRECTS.CHECKOUT };
      }
      case CONST_REDIRECTS.ORDERS.substring(1): {
        return { name: 'Mis Compras / Órdenes', redirectsTo: CONST_REDIRECTS.ORDERS };
      }
      case 'order': {
        return { name: 'Órdenes', redirectsTo: CONST_REDIRECTS.ORDERS };
      }
      case 'ordercancelled': {
        return { name: 'Órdenes', redirectsTo: CONST_REDIRECTS.ORDERS };
      }
      case 'orderreturn': {
        return { name: 'Órdenes ', redirectsTo: CONST_REDIRECTS.ORDERS };
      }
      case 'orderreturned': {
        return { name: 'Órdenes ', redirectsTo: CONST_REDIRECTS.ORDERS };
      }
      case 'list': {
        return { name: 'Mis Compras / Lista de compras', redirectsTo: CONST_REDIRECTS.SHOPPING_LIST };
      }
      case CONST_REDIRECTS.SHOPPING_LIST.substring(1): {
        return { name: 'Mis Compras / Lista de compras', redirectsTo: CONST_REDIRECTS.SHOPPING_LIST };
      }
      case CONST_REDIRECTS.ROTOPLAS_CREDIT.substring(1): {
        return { name: 'Movimientos / Facilidades Rotoplas', redirectsTo: CONST_REDIRECTS.ROTOPLAS_CREDIT };
      }
      case CONST_REDIRECTS.TRANSACTION_HISTORY.substring(1): {
        return { name: 'Movimientos / Descarga tus facturas', redirectsTo: CONST_REDIRECTS.TRANSACTION_HISTORY };
      }
      case CONST_REDIRECTS.CANCELATIONS.substring(1): {
        return { name: 'Incidencias / Cancelaciones', redirectsTo: CONST_REDIRECTS.CANCELATIONS };
      }
      case CONST_REDIRECTS.DEVOLUTIONS.substring(1): {
        return { name: 'Incidencias / Devoluciones ', redirectsTo: CONST_REDIRECTS.DEVOLUTIONS };
      }
      case CONST_REDIRECTS.ACCOUNT_CONFIGURATION.substring(1): {
        return {
          name: 'Configuración / Mi cuenta',
          redirectsTo: CONST_REDIRECTS.ACCOUNT_CONFIGURATION,
        };
      }
      case CONST_REDIRECTS.ANNOUNCEMENTS.substring(1): {
        return { name: 'Comunicados ', redirectsTo: CONST_REDIRECTS.ACCOUNT_CONFIGURATION };
      }
      case CONST_REDIRECTS.TERMS_AND_CONDITIONS.substring(1): {
        return { name: 'Términos y condiciones', redirectsTo: CONST_REDIRECTS.TERMS_AND_CONDITIONS };
      }
      case CONST_REDIRECTS.PRIVACY_NOTICE.substring(1): {
        return { name: 'Aviso de privacidad', redirectsTo: CONST_REDIRECTS.PRIVACY_NOTICE };
      }
      case CONST_REDIRECTS.USERS_MANAGEMENT.substring(1): {
        return { name: 'Configuración / Administración de usuarios', redirectsTo: CONST_REDIRECTS.USERS_MANAGEMENT };
      }

      default: {
        return { name: path, redirectsTo: '#' };
      }
    }
  });

  return (
    <div className={styles['container']}>
      {breadCrumbMappedNames?.map((path, i) => {
        const lastElement = breadCrumbPaths.length - 1 === i;
        return (
          <>
            <div className={`${styles[lastElement ? 'selected' : '']}`}>
              <Link href={path.redirectsTo}>{breadCrumbPaths.length === 1 ? 'Home / Mis Compras' : path.name}</Link>
            </div>
            {!lastElement && <div className={`${styles['separator']}`}>/</div>}
          </>
        );
      })}
    </div>
  );
};

export default CustomBreadcrumb;

import axios from 'axios';
import CONST from '@/shared/consts/CONST_PROJECT.json';

export function api_getAccessToken() {
  return axios
    .post(
      `${CONST.AUTH_URL}/oauth/token?grant_type=client_credentials`,
      {},
      {
        validateStatus: function (status) {
          return status < 501; // Resolve only if the status code is less than 500
        },
        auth: {
          username: CONST.CLIENT_ID,
          password: CONST.CLIENT_SECRET,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_login(username, password) {
  return axios
    .post(
      `${CONST.AUTH_URL}/oauth/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/customers/token?grant_type=password&username=${username}&password=${password}`,
      {
        validateStatus: function (status) {
          return status < 501; // Resolve only if the status code is less than 500
        },
      },
      {
        auth: {
          username: CONST.CLIENT_ID,
          password: CONST.CLIENT_SECRET,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_tokenInstrospection(token) {
  return axios
    .post(
      `${CONST.AUTH_URL}/oauth/introspect?token=${token}`,
      {
        validateStatus: function (status) {
          return status < 501; // Resolve only if the status code is less than 500
        },
      },
      {
        auth: {
          username: CONST.CLIENT_ID,
          password: CONST.CLIENT_SECRET,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

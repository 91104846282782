// import * as React from 'react';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './styles.module.scss';
import { CloseIcon } from '@/shared/icons';
import Button from '@/shared/components/button/Button';

type ModalProps = {
    style?: React.CSSProperties;
    label1: string;
    labelButton1?: string;
    labelButton2?: string;
    disabledButton?: boolean;
    buttonAcceptStyle?: React.CSSProperties;
    fluidButton?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onCancel?: MouseEventHandler<HTMLButtonElement> | undefined;
    variant?: string;
    open: boolean;
    onClose: () => void;
    closeButton?: boolean;
    className?: string;
};

const ModalCustumized = ({
    style,
    children,
    label1,
    labelButton1,
    disabledButton = false,
    buttonAcceptStyle,
    fluidButton = false,
    labelButton2,
    onClick,
    onCancel,
    variant,
    open,
    onClose,
    closeButton = true,
    className,
}: PropsWithChildren<ModalProps>) => {
    return (
        <Modal open={open} onClose={onClose} className={className} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={styles['box']} style={style}>
                {closeButton ? (
                    <div className={styles['icon-container']}>
                        <button onClick={onClose}>
                            <CloseIcon />
                        </button>
                    </div>
                ) : (
                    <></>
                )}
                <div className={styles['question-container']}>
                    <p className={styles['question-cancel']}>{label1}</p>
                </div>
                {children}
                <div className={styles['container-buttons']} style={{ width: fluidButton ? '100%' : 'initial' }}>
                    {labelButton2 && (
                        <div>
                            <Button
                                style={{ width: '190px', height: '35px', marginTop: '15px', fontSize: '14px' }}
                                variant="primary"
                                onClick={onCancel || onClose}
                            >
                                {labelButton2}
                            </Button>
                        </div>
                    )}
                    {labelButton1 && (
                        <div style={{ width: fluidButton ? '100%' : 'initial' }}>
                            <Button
                                disabled={disabledButton}
                                style={{
                                    width: fluidButton ? '100%' : '190px',
                                    height: '35px',
                                    marginTop: '15px',
                                    fontSize: '14px',
                                    ...buttonAcceptStyle,
                                }}
                                variant={variant ? variant : 'secondary'}
                                onClick={onClick}
                            >
                                {labelButton1}
                            </Button>
                        </div>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default ModalCustumized;

import React, { CSSProperties } from 'react';
import { MinusIcon, AddIcon } from '@/shared/icons';

function ButtonAdd({
  style = {},
  disabled = false,
  quantity = 1,
  onAdd = () => {},
  onRemove = () => {},
}: {
  style?: CSSProperties;
  disabled?: boolean;
  quantity: number;
  onAdd: Function;
  onRemove: Function;
}) {
  const backgroundColor = '#F7F7F7 ';

  const defaultStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    ...style,
  };

  return (
    <div style={defaultStyle}>
      <div
        style={{
          flexBasis: '33%',
          display: 'flex',
          borderRadius: '36px 0px 0px 36px',
          backgroundColor,
          cursor: disabled ? 'default' : 'pointer',
          border: '2px solid #D8D8D8',
          userSelect: 'none',
        }}
        onClick={() => {
          if (disabled) return;
          if (quantity < 1) return;
          onRemove();
        }}
      >
        <span style={{ margin: 'auto' }}>{!disabled && <MinusIcon />}</span>
      </div>
      <div
        style={{
          flexBasis: '33%',
          display: 'flex',
          backgroundColor: 'white',
          borderTop: '2px solid #D8D8D8',
          borderLeft: '1px solid #D8D8D8',
          borderRight: '1px solid #D8D8D8',
          borderBottom: '2px solid #D8D8D8',
        }}
      >
        <span style={{ margin: 'auto', lineHeight: '0px' }}>{quantity}</span>
      </div>

      <div
        style={{
          flexBasis: '33%',
          display: 'flex',
          borderRadius: '0px 36px 36px 0px',
          backgroundColor,
          cursor: 'pointer',
          border: '2px solid #D8D8D8',
          userSelect: 'none',
        }}
        onClick={() => {
          if (disabled) return;
          onAdd();
        }}
      >
        <span style={{ margin: 'auto' }}>
          <AddIcon />
        </span>
      </div>
    </div>
  );
}

export default ButtonAdd;

import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import styles from './Notifications.module.scss';
import { CrossCircleIcon } from '@/shared/icons';
import { AppContext } from 'context/appContext/AppContextProvider';
import { INotification, IPushNotification } from './MainLayout';


const Notifications = ({ callbackify_handleView }: { callbackify_handleView: Function }) => {
  const context = useContext(AppContext)

  return (
    <>
      <div className={styles['header']}>
        <div>
          <div>Notificaciones</div>
          <div>No leídas ({context.appContext.notifications.length})</div>
        </div>
        <div>
          <div
            onClick={() => {
              callbackify_handleView();
            }}
          >
            <CrossCircleIcon />
          </div>
        </div>
      </div>
      <div className={styles['notification-cards']}>
        {context?.appContext?.notifications?.map((el) => (
          <div className={styles['card']}>
            <div>
              <div>Orden: {el.orderNumber}</div>
              <div className={styles['icon-container']}>
                <div />
              </div>
            </div>
            <div>
              Tienes un articulo con estatus: <span style={{fontWeight:"600", color:"black"}}>{ el.estatus }</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Notifications;

import React from "react";
import styles from './styles.module.scss'

import { CloseIcon } from '@/shared/icons';

const ResumenBuy = ({ closeDrawer }: { closeDrawer:any }) => {
    return <>
    <div className={styles['container-headers']}>
        <div className={styles['header']}>
            <div>
                <p className={styles['text-compra-rapida']}>Compra rápida</p>
                <div className={styles['container-close-icon']}>
                    <button onClick={closeDrawer}>
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </div>
    </div>
</>
}

export default ResumenBuy
import React, { useState, useEffect } from 'react';
import CustomGrid from '@/shared/components/grid/CustomGrid';
import Pagination from '@mui/material/Pagination';

export type AccountGridRowType = {
  branch: string;
  address: string;
};

const Grid = ({ rows }: { rows: AccountGridRowType[] }) => {
  const [gridApi, setGridApi] = useState<any>();
  const [pagination, setPagination] = useState({
    data: [],
    limit: 5,
    activePage: 1,
    page: 1,
  });

  const [columnDefs] = useState([
    { field: 'branch', headerName: 'Sucursal', width: 120, unSortIcon: true },
    { field: 'address', headerName: 'Dirección', unSortIcon: true },
  ]);

  const handlePagination = (e: any, pageNumber: number) => {
    const direction = pageNumber > pagination.activePage ? 'next' : 'previous';

    setPagination((prev) => ({ ...prev, activePage: pageNumber, page: pageNumber }));

    if (e.target?.innerText) {
      gridApi?.paginationGoToPage(Number(e.target?.innerText));
    }

    if (direction === 'next') {
      gridApi?.paginationGoToNextPage();
    }

    if (direction === 'previous') {
      gridApi?.paginationGoToPreviousPage();
    }
  };

  return (
    <>
      <CustomGrid
        pagination={true}
        paginationPageSize={10}
        height={500}
        rowData={rows}
        columnDefs={columnDefs}
        // @ts-ignore
        onGridReady={(params: any) => {
          setGridApi(params.api);
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', height: '50px', alignItems: 'center' }}>
        {/* <Pagination
          page={pagination.activePage}
          count={Math.ceil(rows.length / (gridApi?.paginationGetPageSize() || 1))}
          color="primary"
          onChange={handlePagination}
        /> */}
      </div>
    </>
  );
};

export default Grid;

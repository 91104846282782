import { useState, useRef, ReactElement, CSSProperties, MutableRefObject } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';

//targetRef is a reference from the div that is gonna be attached to
const CustomOverlay = ({
  children,
  style,
  targetRef,
  show,
  placement = 'right',
}: {
  children: ReactElement | string;
  style?: CSSProperties;
  targetRef: MutableRefObject<null>;
  show: boolean;
  placement?: 'right' | 'left' | 'top' | 'bottom';
}) => {
  return (
    <div>
      <Overlay target={targetRef.current} show={show} placement={placement} >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              // padding: '2px 10px',
              border: '1px solid #D8D8D8',
              // color: 'white',
              borderRadius: 3,
              zIndex:1,
              ...props.style,
              ...style,
            }}
          >
            {children}
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default CustomOverlay;

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import CustomSidebar from './CustomSidebar';
import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.scss';
import CustomBreadcrumb from './CustomBreadcrumb';
import { findCookie } from 'frontastic/tastics/bebbia/shared/functions/cookies';

import { ArrowIcon } from '@/shared/icons';
import { getCookieValue } from '@/shared/functions/cookies';
import { IBreadCrumbPath } from '@/shared/interfaces/IBreadCrumbPath';

import { useRouter } from 'next/router';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';

import { Drawer } from '@mui/material';
import CustomSidebarCollapsed from './CustomSidebarCollapsed';
import { Toast, notifyError } from '../toast/Toast';
import { logout } from '@/shared/functions/logout';
import { Cart } from '@commercetools/platform-sdk';
import { AppContext } from 'context/appContext/AppContextProvider';
import { getCredentials, initialLoading } from 'context/appContext/initialLoading';
import Loader from '../loader/Loader';
import { apiRoot, ctCustomer } from '@/shared/client';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { sdk_loadOrders } from 'context/appContext/loadingFunctions';
import { IStatusCustom } from '@/shared/functions/status';
import { SearchStatus } from '@/shared/interfaces/ITableCard';
import { Order } from '@commercetools/platform-sdk';

export interface IPushNotification {
  orders: Order[]
}

export interface INotification {
  orderNumber: string;
  estatus: string;
}

const initialPushNotification: IPushNotification = {
  orders: [],
}

const MainLayout = (props: { children: React.FunctionComponent | ReactElement | string | any }) => {
  const router = useRouter();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [{ orders }, setOrders] = useState<IPushNotification>(initialPushNotification)
  const context = useContext(AppContext);


  const nameCustomField = "Descripcion-estatus"

  const parseToJson = (order: Order): IStatusCustom[] => {
    if(!order?.custom?.fields["Descripcion-estatus"]) return []
    const custom: string = order?.custom?.fields["Descripcion-estatus"].replace(/'/g, '"');
    if (!custom.includes('[')) return []
    const jsonParse: IStatusCustom[] = JSON.parse(custom)
    return jsonParse
  }

  const loadNotifications = async () => {
    try {
      const ordersSdk = await sdk_loadOrders();
      if (!ordersSdk.statusCode || ordersSdk.statusCode >= 300) throw new Error("Notificaciones: No se obtuvieron las ordenes");
      let oldNotifications = [...context.appContext.notifications];
      let newNotifications: INotification[] = []
      setOrders(prevState => {
        const updatedOrders = ordersSdk.body.results;
        console.log(oldNotifications)
         // Usar el valor actualizado del contexto
        prevState.orders.forEach(order => {
          const newOrder = updatedOrders.find(nOrder => nOrder.orderNumber === order.orderNumber);
          if (!newOrder) return null;
  
          const oldCustomOrder = parseToJson(order);
          const newCustomOrder = parseToJson(newOrder);
  
          const changedStatus = newCustomOrder.filter(oldCustom => {
            const newCustom = oldCustomOrder.find(c => c.idOrden === oldCustom.idOrden);
            return newCustom && oldCustom.idEstatus !== newCustom.idEstatus;
          });
          if (changedStatus.length === 0) return null;


          context.addNotification({
            orderNumber: order.orderNumber || "",
            //@ts-ignore
            estatus: SearchStatus[changedStatus[0].idEstatus]?.name || "",
          });
        });
        return { orders: updatedOrders };
      });
      
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setTimeout(() => {loadNotifications()}, 5000)
  }, [orders])

  //-----------logout when session is not valid-------------------------
  const validateSession = async () => {
    const { Email, Password } = getCredentials();
    try {
      if (!Email && !Password || Email == "" && Password == "") return
      await ctCustomer(Email, Password)
        .login()
        .post({ body: { email: Email, password: Password } })
        .execute();
    } catch (e) {
      if (findCookie("changedPassword")) {
        //delete cookie setted when changedPassword, avoiding validateSession 1 time after changed password on AccountConfiguration
        document.cookie = 'changedPassword=true; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        return
      }
      notifyError('Tu sesión ha expirado');
      logout();
    }
  };

  const updateContext = async () => {
    const res = await initialLoading();
    if (!res) {
      // notifyError('error al cargar, intente nuevamente...');
      return;
    }
    context.setBussinessUnits(res.bussinessUnits);
    context.setMyBussinessUnit(res.myBussinessUnit);
    context.setCustomer(res.customer);
    context.setCart(res.cart);
    context.setOrders(res.orders);
    context.setShoppingLists(res.shoppingLists);
    context.setNewProductsSlider(res.newProductsSlider);
    context.setCategories(res.categories);
    context.setDataLimiteCredito(res.dataLimiteCredito);
    context.setIsLoaded(true);
  };

  let idTimeout: any
  const onMouseMoved = () => {
    clearTimeout(idTimeout)
    idTimeout = setTimeout(() => {
      logout()
    }, 30 * 60 * 1000)
  }

  const getSidebarState = () => {
    const sidebarCollapsedString: string | null = localStorage.getItem('sidebarCollapsed');
    if (!sidebarCollapsedString) return;
    const sidebarBoolean = sidebarCollapsedString === 'true';
    setIsCollapsed(sidebarBoolean);
  };

  useEffect(() => {
    getSidebarState();
    document.addEventListener('mousemove', onMouseMoved, false);
  }, []);

  useEffect(() => {
    if (!findCookie('token') || !findCookie('login')) {
      if (findCookie("changedPassword")) return
      setTimeout(() => {
        notifyError("Redirigiendo a login")
        logout();
        return;
      }, 200)
    }
    if (findCookie("changedPassword")) {
      //delete cookie setted when changedPassword, avoiding validateSession 1 time after changed password on AccountConfiguration
      document.cookie = 'changedPassword=true; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      return
    }
    validateSession();
  }, []);
  //-----------END logout when session is not valid-------------------------

  useEffect(() => {
    if (!context.appContext.isLoaded) updateContext();
  }, [context.appContext.isLoaded]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        anchor="left"
        open={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
      >
        <CustomSidebar user={context.appContext.customer} />
      </Drawer>
      <Toast />
      <div className={`${styles['main-container']} ${styles[isCollapsed ? 'collapse-sidebar' : '']}`}>
        <div className={`${styles['sidebar']}`}>
          <button
            className={styles['collapser-button']}
            onClick={() => {
              const newState: boolean = !isCollapsed;
              setIsCollapsed(newState);
              localStorage.setItem('sidebarCollapsed', String(newState));
            }}
          >
            <ArrowIcon style={{ transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
          </button>

          {isCollapsed && <CustomSidebarCollapsed user={context.appContext.customer} />}
          {!isCollapsed && <CustomSidebar user={context.appContext.customer} />}
        </div>
        <div className={styles['header']}>
          <Header
            setIsOpenDrawer={setIsOpenDrawer}
            cartItemsQuantity={context.appContext.cart?.totalLineItemQuantity}
            customer={context.appContext.customer}
          />
        </div>
        {context.appContext.customer && Object.keys(context.appContext.customer).length > 0 && (
          <div className={styles['container']}>{props.children}</div>
        )}
        <div className={styles['footer'] + ' ' + styles[isCollapsed ? 'footer-w-sidebar-collapsed' : '']}>
          <Footer />
        </div>
      </div>
      {!context.appContext.isLoaded ? <Loader message="" /> : <></>}
    </>
  );
};

export default MainLayout;

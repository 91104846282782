import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './CustomSidebar.module.scss';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';
import Accordion from 'react-bootstrap/Accordion';
import { capitalizeEachFirstLetter } from 'shared/functions/stringFunctions';
import {
  LogoIcon,
  CompraB2BIcon,
  MisVentasECommerceIcon,
  MiLineaCreditoIcon,
  ComunicadosIcon,
  ConfiguracionIcon,
  NotificationIcon,
  LogoutIcon,
  IncidenciaIcon,
  ArrowSidebar,
} from '@/shared/icons';
import { logout } from '@/shared/functions/logout';
import Notifications from './Notifications';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Customer } from '@commercetools/platform-sdk';
import { rol } from '@/shared/roles/featureFlags';
import CONST_REDIRECT from "@/shared/consts/CONST_REDIRECTS.json"


import { rolMapper } from 'frontastic/tastics/b2b/accountConfiguration/AccountConfiguration';
import { INotification, IPushNotification } from './MainLayout';

type sectionModel = {
  id: string;
  icon: ReactElement;
  sectionName: string;
  sectionActive: boolean;
  url?: string;
  children?: {
    url: string;
    name: string;
    active: boolean;
  }[];
};

const initialSections: sectionModel[] = [
  {
    id: '0',
    icon: <CompraB2BIcon />,
    sectionName: 'Mis compras',
    sectionActive: false,
    children: [
      { url: CONST_REDIRECT.ORDERS, name: 'Órdenes', active: false },
      { url: CONST_REDIRECT.PLP, name: 'Catálogo', active: false },
      { url: CONST_REDIRECT.SHOPPING_LIST, name: 'Lista de compras', active: false },
      { url: CONST_REDIRECT.ORDERSOFFLINES, name: "Ordenes omnicanal", active: false}
    ],
  },
  {
    id: '1',
    icon: <MisVentasECommerceIcon />,
    sectionName: 'Mis Ventas E commerce',
    sectionActive: false,
    children: [
      { url: CONST_REDIRECT.ECOMMERCE_B2C, name: 'Órdenes', active: false },
    ],
  },
  {
    id: '2',
    icon: <MiLineaCreditoIcon />,
    sectionName: 'Movimientos',
    sectionActive: false,
    children: [
      { url: CONST_REDIRECT.ROTOPLAS_CREDIT, name: 'Facilidades Rotoplas', active: false },
      { url: CONST_REDIRECT.TRANSACTION_HISTORY, name: 'Descarga tus facturas', active: false },
    ],
  },
  {
    id: '3',
    icon: <IncidenciaIcon />,
    sectionName: 'Incidencias',
    sectionActive: false,
    children: [
      { url: CONST_REDIRECT.CANCELATIONS, name: 'Cancelaciones', active: false },
      { url: CONST_REDIRECT.DEVOLUTIONS, name: 'Devoluciones', active: false },
    ],
  },
  {
    id: '4',
    icon: <ComunicadosIcon />,
    sectionName: 'Comunicados',
    sectionActive: false,
    url: CONST_REDIRECT.ANNOUNCEMENTS,
  },
  {
    id: '5',
    icon: <ConfiguracionIcon />,
    sectionName: 'Configuración',
    sectionActive: false,
    children: [
      { url: CONST_REDIRECT.ACCOUNT_CONFIGURATION, name: 'Mi cuenta', active: false },
      { url: CONST_REDIRECT.USERS_MANAGEMENT, name: 'Administración de usuarios', active: false },
    ],
  },
];

const CustomSidebar: React.FC<{ user: Customer| null }> = ({ user }) => {
  const router = useRouter();
  const [sections, setSections] = useState<sectionModel[]>(initialSections); //active url
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); //handle onClick
  const [view, setView] = useState(0);

  const handleOpenDropdown = (sectionId: string) => {
    if (openDropdown == sectionId) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(sectionId);
    }
  };

  useEffect(() => {
    setSections(
      sections.map((section) => {
        section.sectionActive = false;
        if (section.children) {
          section?.children?.map((c) => {
            c.active = false;
            if (c?.url == router.asPath) {
              c.active = true;
              section.sectionActive = true;
              setOpenDropdown(section.id);
            }
          });
          return section;
        } else {
          if (section.url == router.asPath) {
            section.sectionActive = true;
            setOpenDropdown(section.id);
          }
        }
        return section;
      }),
    );
  }, []);

  return (
    <div className={styles['side']}>
      {view === 0 && (
        <>
          <div className={styles['logo']}>
            <a onClick={()=>{
              //no sirvió el Link :(
              router.push(CONST_REDIRECTS.HOME)
            }}>
              <LogoIcon />
            </a>
            <div
              className={styles['icon-notifications']}
              onClick={() => {
                setView(1);
              }}
            >
              <NotificationIcon />
            </div>
            <div className={styles['full-name']}>
              {user?.firstName} {user?.lastName}
            </div>
            <div className={styles['rol']}>{user?.custom?.fields.roles? rolMapper[user.custom?.fields.roles] : ''}</div>
          </div>
          <ul className={styles['menu-container']}>
            {sections.map((section) => {
              if (
                section.sectionName === 'Movimientos' &&
                rol[user?.custom?.fields.roles] &&
                !rol[user?.custom?.fields.roles]['seeMovementsTab']
              )
                return;

                if (
                  section.sectionName === 'Comunicados' &&
                  rol[user?.custom?.fields.roles] &&
                  !rol[user?.custom?.fields.roles]['seeComunicados']
                )
                  return;

                  if (
                    section.sectionName === 'Mis Ventas E commerce' &&
                    rol[user?.custom?.fields.roles] &&
                    !rol[user?.custom?.fields.roles]['seeEcommerceOrders']
                  )
                    return;

              if (section.children) {
                return (
                  <li key={section.id}>
                    <div
                      className={`${styles['menu-item-dropdown']} ${section.sectionActive ? styles['active'] : ''} ${
                        openDropdown == section.id ? styles['open'] : ''
                      }`}
                      onClick={() => {
                        handleOpenDropdown(section.id);
                      }}
                    >
                      {section.icon}
                      {section.sectionName}
                      <div className={styles['dropdown-arrow']}>
                        <ArrowSidebar />
                      </div>
                    </div>
                    <ul className={styles['sections-container']}>
                      {section.children.map((sectionItem) => {
                        if (
                          sectionItem.url === CONST_REDIRECT.USERS_MANAGEMENT &&
                          rol[user?.custom?.fields.roles] &&
                          !rol[user?.custom?.fields.roles]['seeUserManagement']
                        )
                          return;
                        if (
                          sectionItem.url === CONST_REDIRECT.ROTOPLAS_CREDIT &&
                          rol[user?.custom?.fields.roles] &&
                          !rol[user?.custom?.fields.roles]['seeRotoplasCredit']
                        )
                          return;

                          if (
                            sectionItem.url === CONST_REDIRECT.TRANSACTION_HISTORY &&
                            rol[user?.custom?.fields.roles] &&
                            !rol[user?.custom?.fields.roles]['seeTransactionHistory']
                          )
                            return;

                            if (
                              sectionItem.url === CONST_REDIRECT.PLP &&
                              rol[user?.custom?.fields.roles] &&
                              !rol[user?.custom?.fields.roles]['seePLP']
                            )
                              return;

                              if (
                                sectionItem.url === CONST_REDIRECT.SHOPPING_LIST &&
                                rol[user?.custom?.fields.roles] &&
                                !rol[user?.custom?.fields.roles]['seeShoppingLists']
                              )
                                return;

                        return (
                          <li
                            key={`${section.id}-${sectionItem.name}`}
                            className={`${sectionItem.active ? styles['active'] : ''}`}
                            onClick={() => {
                              return;
                              alert('click2');
                              handleOpenDropdown('');
                            }}
                          >
                            <Link href={sectionItem.url}>{sectionItem.name}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li
                    className={`${styles['menu-item']} ${
                      section.sectionActive || openDropdown == section.id ? styles['active'] : ''
                    }`}
                  >
                    <Link href={section.url || ''}>
                      <span>
                        {section.icon}
                        {section.sectionName}
                      </span>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>

          <div className={styles['log-out-container']} onClick={() => logout()}>
            <div className={styles['log-out-icon']}>
              <div className={styles['icon-2']}>
                <LogoutIcon />
              </div>
            </div>
            <p className={styles['log-out-text']}>Cerrar sesión</p>
          </div>
        </>
      )}
      {view === 1 && (
        <>
          <div className={styles['logo']} style={{ paddingRight: '5px', height: '100%' }}>
            <a  onClick={()=>{
              //no sirvió el Link :(
              router.push(CONST_REDIRECTS.HOME)
            }}>
              <LogoIcon />
            </a>
            <div style={{ height: '100%' }}>
              <Notifications callbackify_handleView={() => setView(0)}  />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomSidebar;

import React from 'react';
import { IOrderData } from '../../home/Home';

export interface ITableCard {
    Svg: React.JSX.Element;
    OrderStatus: IOrderStatus[] | null | undefined;
    ordersData: IOrderData[];
}

export interface OrderInformation {
    OrderNumber: string;
    Color: string;
    State: string;
    Skus: number;
    TotalPrice: number | string;
}

export interface IOrderStatus {
    Color: string;
    Number: number;
    TypeOrder: string;
}
type Dictionary = Record<number, IOrderStatus>;

export type DictionaryStates = Record<string, any>;

export const mapStatesOrders: DictionaryStates = {
    Cargando: { name: "Cargando", color: 'status-circle-green-table' },
    Pendiente: { name: "Pendiente", color: 'status-circle-green-table' },
    Created: { name: "Creado", color: 'status-circle-green-table' },
    Liberado: { name: "Liberado", color: 'status-circle-green-table' },
    Open: { name: 'Pendiente', color: 'status-circle-green-table' },
    "Verificación SAC": { name: 'Verificación SAC', color: 'status-circle-green-table' },
    "Verificar SAC": { name: 'Verificación SAC', color: 'status-circle-green-table' },
    "Facturado Parcial": { name: "Facturado Parcial", color: "status-circle-blue-cloud-table" },
    "En Proceso": { name: "En Proceso", color: "status-circle-blue-cloud-table" },
    Concluido: { name: "Concluido", color: "status-circle-blue-cloud-table" },
    Abierto: { name: "Abierto", color: "status-circle-blue-cloud-table" },
    Completo: { name: "Completo", color: "status-circle-blue-cloud-table" },
    Complete: { name: 'Liberado', color: 'status-circle-blue-table' },
    Delivered: { name: 'Entregada', color: 'status-circle-blue-table' },
    Cancelled: { name: 'Cancelada', color: 'status-circle-red-table' },
    Shipped: { name: 'En camino', color: 'status-circle-orange-table' },
    Confirmed: { name: 'Liberado', color: 'status-circle-green-table' },
    confirmado: { name: 'Confirmado', color: 'status-circle-green-table' },
};


export const SearchStatus = {
    0:  { name: "Creado", color: 'status-circle-green-table' },
    1:  { name: "Verificación SAC", color: 'status-circle-yellow-table' },
    5:  { name: "Error en SAP", color: 'status-circle-red-table' },
    10: { name: "Verificación SAC", color: 'status-circle-yellow-table' },
    20: { name: "Liberado", color: 'status-circle-green-table' },
    30: { name: "Cancelado", color: 'status-circle-red-table' },
    40: { name: "En Proceso", color: 'status-circle-green-table' },
    50: { name: "Facturado Parcial", color: 'status-circle-blue-table' },
    60: { name: "Concluido", color: 'status-circle-green-table' },
    70: { name: "Completo", color: 'status-circle-green-table' },
    80: { name: "Abierto", color: 'status-circle-blue-cloud-table' },
    90: { name: "Pendiente", color: 'status-circle-yellow-table' },
    100: { name: "Devuelto", color: 'status-circle-red-table' },
}

export const StatusMapOrders: Dictionary = {
    20: {
        Color: 'status-circle-green',
        TypeOrder: 'Liberado',
        Number: 0,
    },
    60: {
        Color: 'status-circle-green',
        TypeOrder: 'Concluido',
        Number: 0,
    },
    70: {
        Color: 'status-circle-green',
        TypeOrder: 'Completado',
        Number: 0,
    },
    100: {
        Color: 'status-circle-red-table',
        TypeOrder: 'Devoluciones',
        Number: 0,
    },
    30: {
        Color: 'status-circle-red-table',
        TypeOrder: 'Cancelaciones',
        Number: 0,
    },
};


export const initialDataTable: IOrderStatus[] = [
    {
        Color: 'status-circle-green',
        TypeOrder: 'Liberado',
        Number: 0,
    },
    {
        Color: 'status-circle-green',
        TypeOrder: 'Concluido',
        Number: 0,
    },
    {
        Color: 'status-circle-green',
        TypeOrder: 'Completado',
        Number: 0,
    },
    {
        Color: 'status-circle-red',
        TypeOrder: 'Devoluciones',
        Number: 0,
    },
    {
        Color: 'status-circle-red',
        TypeOrder: 'Cancelaciones',
        Number: 0,
    },
];

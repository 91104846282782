import React from 'react';
import styles from './styles.module.scss';
import { CloseIcon } from '@/shared/icons';
import { ArrowRightIcon } from '@/shared/icons';

const Options = ({ setComponent, closeDrawer }: { setComponent: any; closeDrawer: any }) => {
  return (
    <>
      <div className={styles['container-headers']}>
        <div className={styles['header']}>
          <div>
            <p className={styles['text-compra-rapida']}>Compra rápida</p>
            <div className={styles['container-close-icon']}>
              <button onClick={closeDrawer}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['container-body']}>
        <div className={styles['option-compra']}>
          <div>
            <span>
              Con Compra rápida puedes agregar artículos a tu carrito de manera más ágil. Puedes cargar tu lista de
              compra desde un archivo CSV, buscar cada artículo por SKU, nombre o modelo. También puedes añadir las
              listas de compras que tengas registradas en la plataforma.
            </span>
          </div>
        </div>
        <div
          className={styles['option-compra']}
          onClick={() => {
            setComponent('by-csv');
          }}
        >
          <div>
            <p className={styles['text-compra-rapida']}>Comprar con archivo CSV</p>
            <ArrowRightIcon color="#000255" />
          </div>
        </div>
        <div
          className={styles['option-compra']}
          onClick={() => {
            setComponent('by-sku');
          }}
        >
          <div>
            <p className={styles['text-compra-rapida']}>Comprar por búsqueda de SKU</p>
            <ArrowRightIcon color="#000255" />
          </div>
        </div>
        <div
          className={styles['option-compra']}
          onClick={() => {
            setComponent('by-list');
          }}
        >
          <div>
            <p className={styles['text-compra-rapida']}>Comprar con lista de compra</p>
            <ArrowRightIcon color="#000255" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Options;
